<template>
   <div class="w-full flex justify-center items-center flex-col relative py-12">

    <div class="absolute inset-0 bg-center bg-grid-white/10 bg-grid-24"></div>


    <div class="absolute top-0 flex justify-center flex-col items-center w-full max-w-[100vw] overflow-hidden py-12 translate-y-[-50%]">
        <div class="rotate-[3deg] relative z-10">
          <ScrollerBar theme="green" direction="right" class="" text="From the Community" />
        </div>
        <div class="rotate-[-3deg] absolute">
          <ScrollerBar theme="green" direction="left" class="opacity-20" text="From the Community" />
        </div>
      </div>


      <!-- <h2 class="text-7xl text-white font-brand font-bold py-12">From the community</h2> -->

      <div class="w-full relative z-20 flex justify-center items-center flex-col mt-12">
        <div class="w-full flex justify-center items-center px-3">
          <ClientOnly fallback-tag="span" fallback="Loading video...">
            <iframe src="https://www.youtube.com/embed/-swIyMFe66U?si=pAvGMGtXUxmLk-3l" class="w-full max-w-screen-md border rounded-md border-white/20 col-span-3 aspect-video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="OXBULL: The Most Underrated Launchpad in Crypto!!??" />
          </ClientOnly>
        </div>
      <div class="w-full max-w-[100vw] overflow-hidden mt-8">
        <LandingScrollingFeedback></LandingScrollingFeedback>
      </div>
      </div>


      
    </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>